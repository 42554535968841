import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import { GiftsResponse } from '@core/models/gift/gifts-response';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GiftService {
    http = inject(HttpClient);

    private giftBaseUrl: string;

    constructor() {
        this.giftBaseUrl = environment.apiUrl + '/gift';
        //his.userSubject = new BehaviorSubject<User>({ user: {}, token: '', refreshToken: ''});
    }

    getGifts(): Observable<GiftsResponse> {
        const reqParams = {
        };
        const options = {params: reqParams};
        return this.http.get<GiftsResponse>(this.giftBaseUrl, options);
    }

}
